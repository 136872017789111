<template>
  <div class="mobile-container">
    <mobileStatusBar mode="dart" title="开通会员" bgColor="#000" />
    <div class="vip-list">
      <div class="vip-item img-contain" v-for="(item, index) in vipList" :key="index" @click="handleShowPopup(index)">
        <img :src="item.Banner" />
      </div>
    </div>
    <van-popup v-model="showPopup" :round="true" position="bottom" @close="showPopup = false">
      <div class="popup-item">
        <div class="popup-header">
          <div class="title">开通会员</div>
          <div class="close-item img-cover" @click="showPopup = false"><img src="../../assets/images/close-icon.png" /></div>
        </div>
        <div class="banner-item"><img :src="selectedVip.TcBanner"></div>
        <div class="vip-des" v-html="selectedVip.Des"></div>
        <div class="footer-item public-btn">
          <div class="des">仅{{ selectedVip.Money }}享永久会员特权</div>
          <div class="btn" @click="handleSubmit(selectedVip.Level)">
            <van-loading v-if="submiting" color="#1399ff" />
            <span v-else>立即开通</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "Vip",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      vipList: [],
      showPopup: false,
      selectedVip: {}
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    this.handleGetVipList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    async handleGetVipList() {
      const { userInfo } = this
      var res = await this.$API.vipMoney.post({
        Type: 1
      })
      if (res && res.length > 0) {
        const list = res.filter((item) => {
          item.Qy = JSON.parse(item.Qy || '[]')
          return item.Level > userInfo.Level
        })
        if (list.length == 0) {
          list.push(res[res.length - 1])
        }
        this.vipList = list
      }
    },
    handleShowPopup(index) {
      const { vipList } = this
      this.showPopup = true
      this.selectedVip = {
        ...vipList[index]
      }
    },
    async handleSubmit(level) {
      if (this.submiting) {
        return
      }
      this.submiting = true
      var res = await this.$API.buyVip.post({
        Level: level
      })
      this.submiting = false
      if (res.error == 0) {
        this.GetUserInfo()
        this.$toast.success(res.info)
        this.$router.back()
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #000;
}
.vip-list {
  padding: 0 .12rem 0 .32rem;
  .vip-item {
    cursor: pointer;
    margin-bottom: .2rem;
    width: 100%;
    height: 3.5rem;
  }
}
/deep/.van-popup--bottom {
  width: var(--max-width) !important;
  left: 50%;
  margin-left: var(---max-width-50);
}
.popup-item {
  border-radius: .24rem .24rem 0 0;
  background-color: #fff;
  .popup-header {
    position: relative;
    width: 100%;
    padding: .32rem 0;
    height: .48rem;
    line-height: .48rem;
    text-align: center;
    font-weight: 500;
    font-size: .32rem;
    color: #030304;
    .close-item  {
      position: absolute;
      right: .28rem;
      top: .32rem;
      padding: .12rem;
      width: .24rem;
      height: .24rem;
    }
  }
  .banner-item {
    width: 7.06rem;
    padding: 0 .12rem 0 .32rem;
    img {
      width: 100%;
    }
  }
  .vip-des {
    margin: .4rem;
    color: #b3b3b3;
    white-space: pre-wrap;
    line-height: .64rem;
    max-height: 36vh;
    overflow-y: auto;
  }
  .footer-item {
    margin: .4rem .32rem .8rem;
    padding: 0 .2rem 0 .32rem;
    height: 1.08rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: .28rem;
    .btn {
      cursor: pointer;
      width: 1.6rem;
      height: .72rem;
      border-radius: .36rem;
      text-align: center;
      line-height: .72rem;
      background-color: #fff;
      font-weight: bold;
      color: #1399ff;
    }
  }
}
</style>